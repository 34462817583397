export const THEMES = {
  LIGHT: "light",
  DARK: "dark"
};

export const VISIBILITY_FILTERS = {
  ALL: "all",
  COMPLETED: "completed",
  INCOMPLETE: "incomplete"
};
