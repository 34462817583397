import useAuth from "hooks/useAuth";
import Login from "pages/authentication/Login";
import React, { Fragment, useState } from "react";
import { Navigate, useLocation } from "react-router-dom"; // component props interface
import { useAppState } from 'contexts/appState'

const AuthGuard = ({
  children
}) => {
  const [ state ] = useAppState()  
  const {
    pathname
  } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);
  if (!state.isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }
  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <Fragment>{children}</Fragment>;
};
export default AuthGuard;