import LoadingScreen from "components/LoadingScreen";
import { createContext, useEffect, useReducer } from "react";
import { useAppState } from 'contexts/appState'

//import axios from "utils/axios"; // All types
// =============================================

const SqlFunction = require("../functions/sql_functions_app");
const axios = require('axios');

var Types;

(function (Types) {
  Types["Init"] = "INIT";
  Types["Login"] = "LOGIN";
  Types["Logout"] = "LOGOUT";
  Types["Register"] = "REGISTER";
})(Types || (Types = {}));

// ================================================


const ConsultantContext = createContext({ 
  method: "CONSULTANT",
  zetLaden: arg => {},
  getConsultant: (id) => {},
  getConsAct: (id) => {},  
  getConsKen: (id) => {},  
}); // props type

export const ConsultantProvider = ({
  children
}) => {
  const [ state, dispatch ] = useAppState()
  const getConsultant = async (id) => {
  let terdata = await SqlFunction.DoSql('GetConsultantCa',{id:id });
  await dispatch({ 
      type: 'GETCONSULTANT',
      payload: {
        Data: terdata ? terdata : null,
      }
    })
  };

  const getConsAct = async (id) => {
  let terdata = await SqlFunction.DoSql('GetConsActCa',{id:id });
  await dispatch({ 
      type: 'GETCONSACT',
      payload: {
        Data: terdata ? terdata : null,
      }
    })
  };

  const getConsKen = async (id) => {
  let terdata = await SqlFunction.DoSql('GetConsKenCa',{id:id });
  await dispatch({ 
      type: 'GETCONSKEN',
      payload: {
        Data: terdata ? terdata : null,
      }
    })
  };

  const zetLaden = async () => {
    await dispatch({ 
      type: 'ZETLADEN',
    })
  };


  useEffect(() => {
    (async () => {
      try {
          dispatch({
            type: Types.Init,
            payload: {
              isInitialized: false
            }
          });

      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            isInitialized: false
          }
        });
      }
    })();
  }, []);


//  if (state.laden) {
//    return <LoadingScreen />;
//  }

  return <ConsultantContext.Provider value={{ ...state,
    method: "CONSULTANT",
    getConsultant,
    getConsAct,
    getConsKen,
    zetLaden
  }}>
      {children}
    </ConsultantContext.Provider>;
};
export default ConsultantContext;