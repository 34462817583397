import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AppContextProvider } from "contexts/AppContextProvider";


import SettingsProvider from "contexts/SettingsContext";
import TitleContextProvider from "contexts/TitleContext";

import "nprogress/nprogress.css";
import "pure-react-carousel/dist/react-carousel.es.css";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import "react-image-lightbox/style.css";
import { BrowserRouter } from "react-router-dom";
import "simplebar/dist/simplebar.min.css";
import App from "./App";
import "./__fakeApi__";

import { AppStateProvider } from './contexts/appState'

import { initialState, combineReducers } from './reducers'
import counterReducer from './reducers/counterReducer'
import randomReducer from './reducers/randomReducer'
import userReducer from './reducers/userReducer'
import accountReducer from './reducers/accountReducer'
import maindataReducer from './reducers/maindataReducer'
import consultantReducer from './reducers/consultantReducer'
import dropdataReducer from './reducers/dropdataReducer'


const appReducers = combineReducers({
  counter: counterReducer,
  random: randomReducer,
  user: userReducer,
  accounts:accountReducer,
  maindata:maindataReducer,
  consultant:consultantReducer,  
  dropdata:dropdataReducer, 
})


ReactDOM.render(<StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AppStateProvider reducer={appReducers} initialState={initialState}>   
        <AppContextProvider>      
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AppContextProvider>
      </AppStateProvider>
    </LocalizationProvider>
  </StrictMode>, document.getElementById("root"));