import LoadingScreen from "components/LoadingScreen";
import { createContext, useEffect, useReducer } from "react";
import { useAppState } from 'contexts/appState'

//import axios from "utils/axios"; // All types
// =============================================

const SqlFunction = require("../functions/sql_functions_app");
const axios = require('axios');

var Types;

(function (Types) {
  Types["Init"] = "INIT";
  Types["Login"] = "LOGIN";
  Types["Logout"] = "LOGOUT";
  Types["Register"] = "REGISTER";
})(Types || (Types = {}));

// ================================================


const AccountsContext = createContext({ 
  method: "ACCOUNTS",
  getAllAccounts: () => Promise.resolve(),
  getAccountsSelect: () => Promise.resolve()

}); // props type

export const AccountsProvider = ({
  children
}) => {
  const [ state, dispatch ] = useAppState()
  const getAllAccounts = async () => {
    console.log(state)
    let terdata = await SqlFunction.DoSql('GetAllAcc',{});
    console.log(terdata)
    await dispatch({ 
      type: 'ALLACCOUNTS',
      payload: {
        Data: terdata.data,
        Col: terdata.kol
        }
    })
    console.log(state)
  };

  const getAccountsSelect = async () => {
    let terdata = await SqlFunction.DoSql('GetAccView',{});
    await dispatch({ 
      type: 'ACCOUNTSSEL',
      payload: {
        Data: terdata,
        }
    })
  };




  useEffect(() => {
    (async () => {
      try {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false
            }
          });

      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false
          }
        });
      }
    })();
  }, []);


  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return <AccountsContext.Provider value={{ ...state,
    method: "ACCOUNTS",
    getAllAccounts,
    getAccountsSelect,
  }}>
      {children}
    </AccountsContext.Provider>;
};
export default AccountsContext;