const maindataReducer = (state, action = {}) => {
  switch (action.type) {

    case "ALLMAINDATA":
      {
        return { ...state,
                MainDataCol:action.payload.Col,          
                MainDataData:action.payload.Data,
                laden: false,
                MainDataGridName:action.payload.GridName,
                Title:action.payload.Title
        };

      }

    case "ZETLADEN":
      {
        return { ...state,
             laden: true,

        };

      }



    default:
      {
        return state;
      }
  }
}

export default maindataReducer