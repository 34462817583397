import { CssBaseline, ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { Toaster } from "react-hot-toast";
import { useRoutes } from "react-router-dom";
import "./i18n";
import routes from "./routes";
import { ukoTheme } from "./theme";

import { initialState, combineReducers } from './reducers'
import { AppStateProvider } from './contexts/appState'
import counterReducer from './reducers/counterReducer'
import randomReducer from './reducers/randomReducer'
import userReducer from './reducers/userReducer'
import accountReducer from './reducers/accountReducer'
import dropdataReducer from './reducers/dropdataReducer'

import { LicenseInfo } from '@mui/x-data-grid-pro';

LicenseInfo.setLicenseKey(
  'cc230c8b100a40d5ce4a7765b569de03T1JERVI6MzkwMzEsRVhQSVJZPTE2NzgwMTAzNTkwMDAsS0VZVkVSU0lPTj0x',
);

const appReducers = combineReducers({
  counter: counterReducer,
  random: randomReducer,
  user: userReducer,
  accounts:accountReducer,
  dropdata:dropdataReducer,
})

const App = () => {
  const allPages = useRoutes(routes);
  const {
    settings
  } = useSettings(); // App theme

  const appTheme = ukoTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes
  }); // toaster options

  const toasterOptions = {
    style: {
      fontWeight: 500,
      fontFamily: "'Montserrat', sans-serif"
    }
  };
  return <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
          <RTL direction={appTheme.direction}>
            <CssBaseline />
            <Toaster toastOptions={toasterOptions} />
            {allPages}
          </RTL>
      </ThemeProvider>
    </StyledEngineProvider>;
};

export default App;