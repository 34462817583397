import React from 'react';

import { AccountsProvider } from "./AccountContext";
import { MainDataProvider } from "./MainDataContext";
import { AuthProvider } from "./JWTAuthContext";
import { ConsultantProvider } from "./ConsultantContext";
import { FunctionProvider } from "./FunctionContext";
import { DropDataProvider } from "./DropDataContext";
import SettingsProvider from "./SettingsContext";
import TitleContextProvider from "./TitleContext";

import { combineComponents } from 'utils/combineComponents';

const providers = [
  AccountsProvider,
  ConsultantProvider,
  AuthProvider,
  MainDataProvider,
  SettingsProvider,
  TitleContextProvider,
  FunctionProvider,
  DropDataProvider,  
]
export const AppContextProvider = combineComponents(...providers);