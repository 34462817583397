const userReducer = (state, action = {}) => {
  const { type } = action
  switch (action.type) {
    case "INIT":
      {
        return {...state,
          isInitialized: true,
          user: null,
          isAuthenticated: false,
        };
      }

    case "LOGINUSER":
      {
        console.log(action.payload.user);
        
        var Sec_Cons = {zien:false, edit:false, verwijder:false }
        var Sec_Req = {zien:false, edit:false, verwijder:false }
        var Sec_Acc = {zien:false, edit:false, verwijder:false }
        var Sec_Cont = {zien:false, edit:false, verwijder:false }

        var Sec_Sales = {zien:false, edit:false, verwijder:false }
        var Sec_Hrm = {zien:false, edit:false, verwijder:false }
        var Sec_Recruit = {zien:false, edit:false, verwijder:false }

        var zien = false;
        var edit = false;
        var verwijder = false;

        var secrows = action && action.payload && action.payload.user && action.payload.user.secrows ? action.payload.user.secrows : [];

        for (var i = 0; i < secrows.length; i++) 
        { 
          let rij = secrows[i];
          if (rij.edit === 1) {edit = true}
          if (rij.zien === 1) {zien = true}
          if (rij.verwijder === 1) {verwijder = true}

          switch (rij.security) 
          {
            case 'AllConsultants':
              Sec_Cons = {zien:zien, edit:edit, verwijder:verwijder }
              break;

            case 'AllSal':
              Sec_Sales = {zien:zien, edit:edit, verwijder:verwijder }
              break;

            case 'AllHr':
              Sec_Hrm = {zien:zien, edit:edit, verwijder:verwijder }
              break;

            case 'Recruitement':
              Sec_Recruit = {zien:zien, edit:edit, verwijder:verwijder }
              break;



            case 'AllAccounts':
              Sec_Acc = {zien:zien, edit:edit, verwijder:verwijder }
              break;

            case 'AllRequests':
              Sec_Req = {zien:zien, edit:edit, verwijder:verwijder }
              break;

            case 'AllContacts':
              Sec_Cont = {zien:zien, edit:edit, verwijder:verwijder }
              break;
      
            default:
              break;
          }        
        }


        return { ...state,
          isAuthenticated: true,
          user: action.payload.user,
          Sec_Cons: Sec_Cons,
          Sec_Req: Sec_Req,
          Sec_Acc: Sec_Acc,
          Sec_Cont: Sec_Cont,
          Sec_Sales: Sec_Sales,
          Sec_Recruit: Sec_Recruit,
          Sec_Hrm: Sec_Hrm,

        };
      }

    case "LOGOUT":
      {
        return { ...state,
          user: null,
          isAuthenticated: false
        };
      }

    case "REGISTER":
      {
        return { ...state,
          isAuthenticated: true,
          user: action.payload.user
        };
      }

    default:
      {
        return state;
      }
  }
}

export default userReducer