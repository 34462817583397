const initialState = {
  random: { value: 0 },
  counter: 0,
  isAuthenticated: false,
  isInitialized: false,
  laden:false,
  user: null,
  Title:null,
  UserSelData:null,
  AcountSelData:null,
  AccountSelData:null,
  ContactSelData:null,
  ActtypeSelData:null,
  ActstatusSelData:null,

  ConsultantSelData:null,

  AcountAllData:null,
  AcountAllCol:null,
  MainDataData:null,
  MainDataCol:null,
  MainDataGridName:null,
  ConsDetail:null,
  ConsAct:null,
  ConsKen:null,
  
  Sec_Cons: {zien:false, edit:false, verwijder:false },
  Sec_Req: {zien:false, edit:false, verwijder:false },
  Sec_Acc: {zien:false, edit:false, verwijder:false },
  Sec_Cont: {zien:false, edit:false, verwijder:false },
  Sec_Sales: {zien:false, edit:false, verwijder:false },
  Sec_Hrm: {zien:false, edit:false, verwijder:false },
  Sec_Recruit: {zien:false, edit:false, verwijder:false },

}

const combineReducers = reducers => {
  return (state, action) => {
    return Object.keys(reducers).reduce(
      (acc, prop) => {
        return ({
          ...acc,
          ...reducers[prop]({ [prop]: acc[prop] }, action),
        })
      },
      state
    )
  }
}

export { initialState, combineReducers }