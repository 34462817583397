const consultantReducer = (state, action = {}) => {
  switch (action.type) {

    case "GETCONSULTANT":
      {
        return { ...state,
                ConsDetail:action.payload.Data,          
                laden: false,
                Title:'Consultant',
                ConsAct:null,
                ConsKen:null,
        };

      }

    case "GETCONSACT":
      {
        return { ...state,
                ConsAct:action.payload.Data,          
                laden: false,
        };

      }

    case "GETCONSKEN":
      {
        return { ...state,
                ConsKen:action.payload.Data,          
                laden: false,
        };

      }



    case "ZETLADEN":
      {
        return { ...state,
             laden: true,

        };

      }




    default:
      {
        return state;
      }
  }
}

export default consultantReducer