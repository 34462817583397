const accountReducer = (state, action = {}) => {
  switch (action.type) {
    case "ALLACCOUNTS":
      {
        return { ...state,
                AcountAllCol:action.payload.Col,          
                AcountAllOrigCol:action.payload.Col,          
                AcountAllData:action.payload.Data,
        };

      }

    case "ACCOUNTSSEL":
      {
        return { ...state,
                AcountSelData:action.payload.Data,
        };

      }



    default:
      {
        return state;
      }
  }
}

export default accountReducer