import { LoadingButton } from "@mui/lab";
import { Box, Button, Card, Divider, FormControlLabel, FormHelperText, Switch } from "@mui/material";
import { SocialIconButton, TextFieldWrapper } from "components/authentication/StyledComponents";
import FlexBox from "components/FlexBox";
import LightTextField from "components/LightTextField";
import { H1, H3, Paragraph, Small } from "components/Typography";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import FacebookIcon from "icons/FacebookIcon";
import GoogleIcon from "icons/GoogleIcon";
import { useState, useContext } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppState } from 'contexts/appState'


const Login = () => {
  const {
    login,
    isAuthenticated    
  } = useAuth();

  const [ state, dispatch ] = useAppState()
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    submit: null,
    remember: true
  }; // form field value validation schema


  const validationSchema = Yup.object().shape({
    email: Yup.string().min(3, "Loginnaam moet minsten 3 kararakters bevatten").max(255).required("Naam is verplicht"),
    password: Yup.string().min(6, "Paswoord moet minsten 6 kararakters bevatten").required("Paswoord is verplicht")
  });
  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      setLoading(true);
      login(values.email, values.password).then(() => {
        if (state.isAuthenticated === true)
        {
          navigate("/dashboard");
        }
        else
        {
          setError("Verkeerde Login");
        }
      }).catch(error => {
        setError(error.message);
      });
      setLoading(false);

    }
  });
  return <FlexBox sx={{
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: {
      sm: "100%"
    }
  }}>
      <Card sx={{
      padding: 4,
      maxWidth: 600,
      boxShadow: 1
    }}>
        <FlexBox alignItems="center" flexDirection="column" justifyContent="center" mb={5}>
          <Box width={38} mb={1}>
            <img src="/static/logo/logo.svg" width="100%" alt="Uko Logo" />
          </Box>
          <H1 fontSize={24} fontWeight={700}>
            Inloggen
          </H1>
        </FlexBox>

        <FlexBox justifyContent="space-between" flexWrap="wrap" my="1rem">
          <form noValidate onSubmit={handleSubmit} style={{width: "100%"}}>
            <FlexBox justifyContent="space-between" flexWrap="wrap">
              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Login naam
                </Paragraph>
                <LightTextField fullWidth name="email" type="email" onBlur={handleBlur} onChange={handleChange} value={values.email || ""} error={Boolean(touched.email && errors.email)} helperText={touched.email && errors.email} />
              </TextFieldWrapper>

              <TextFieldWrapper>
                <Paragraph fontWeight={600} mb={1}>
                  Paswoord
                </Paragraph>
                <LightTextField fullWidth name="password" type="password" onBlur={handleBlur} onChange={handleChange} value={values.password || ""} error={Boolean(touched.password && errors.password)} helperText={touched.password && errors.password} />
              </TextFieldWrapper>
            </FlexBox>
            {error && <FormHelperText error sx={{
            mt: 2,
            fontSize: 13,
            fontWeight: 500,
            textAlign: "center"}}>
                {error}
              </FormHelperText>}
            <Box sx={{
            mt: 4
          }}>
              {loading ? <LoadingButton loading fullWidth variant="contained">
                  Log In
                </LoadingButton> : <Button fullWidth type="submit" variant="contained">
                  Log In
                </Button>}
            </Box>
          </form>
        </FlexBox>
      </Card>
    </FlexBox>;
};

export default Login;