import LoadingScreen from "components/LoadingScreen";
import { createContext, useEffect, useReducer } from "react";
import { useAppState } from 'contexts/appState'

//import axios from "utils/axios"; // All types
// =============================================

const SqlFunction = require("../functions/sql_functions_app");
const axios = require('axios');

var Types;

(function (Types) {
  Types["Init"] = "INIT";
})(Types || (Types = {}));

// ================================================


const FunctionContext = createContext({ 
  method: "FUNCTION",
  zetLaden: arg => {},
  getAllowed: (waarde,type) => {},
  MakeId: () => {},  
}); // props type

export const FunctionProvider = ({
  children
}) => {
  const [ state, dispatch ] = useAppState()
  
  const getAllowed = (waarde,type) => {
    console.log(state)
    let field = 'zien';
    if (type) {field = type}
    if (state && state.user && state.user.secrows)
    {
        let data = state.user.secrows
        let result = data.filter(o=> (o.security === waarde || o.name === waarde)  && (o[field] === '1' || o[field] === 1) );
        let terug = false;
        if (Array.isArray(result))
        {
           if (result.length > 0)
           {
                terug = true;
           }
        }
        return terug;
    }
    return false;
  };

  const MakeId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };


  const zetLaden = async () => {
    await dispatch({ 
      type: 'ZETLADEN',
    })
  };


  useEffect(() => {
    (async () => {
      try {
          dispatch({
            type: Types.Init,
            payload: {
              isInitialized: false
            }
          });

      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            isInitialized: false
          }
        });
      }
    })();
  }, []);


//  if (state.laden) {
//    return <LoadingScreen />;
//  }

  return <FunctionContext.Provider value={{ ...state,
    method: "FUNCTION",
    getAllowed,
    MakeId,
    zetLaden
  }}>
      {children}
    </FunctionContext.Provider>;
};
export default FunctionContext;