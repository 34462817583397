const dropdataReducer = (state, action = {}) => {
  switch (action.type) {
    case "ACCOUNTSDROP":
      {
        return { ...state,
          AccountSelData:action.payload.Data,
        };

      }

    case "CONSULTANTSDROP":
      {
        return { ...state,
          ConsultantSelData:action.payload.Data,
        };

      }

    case "CONTACTSDROP":
      {
        return { ...state,
          ContactSelData:action.payload.Data,
        };

      }

    case "USERSDROP":
      {
        return { ...state,
                UserSelData:action.payload.Data,
        };

      }

    case "ACTTYPEDROP":
      {
        return { ...state,
                ActtypeSelData:action.payload.Data,
        };

      }

    case "ACTSTATUSDROP":
      {
        return { ...state,
                ActstatusSelData:action.payload.Data,
        };

      }


    default:
      {
        return state;
      }
  }
}

export default dropdataReducer