import LoadingScreen from "components/LoadingScreen";
import { createContext, useEffect, useReducer } from "react";
import { useAppState } from 'contexts/appState'

//import axios from "utils/axios"; // All types
// =============================================

const SqlFunction = require("../functions/sql_functions_app");
const axios = require('axios');

var Types;

(function (Types) {
  Types["Init"] = "INIT";
  Types["Login"] = "LOGIN";
  Types["Logout"] = "LOGOUT";
  Types["Register"] = "REGISTER";
})(Types || (Types = {}));

// ================================================


const DropDataContext = createContext({ 
  method: "DROPDATA",
  getDropAccounts: () => Promise.resolve(),
  getDropUsers: () => Promise.resolve(),
  getDropConsultants: () => Promise.resolve(),
  getDropContacts: () => Promise.resolve(),
  getDropActtype: () => Promise.resolve(),
  getDropActstatus: () => Promise.resolve(),


}); // props type

export const DropDataProvider = ({
  children
}) => {
  const [ state, dispatch ] = useAppState()
  const getDropAccounts = async () => {
    let terdata = await SqlFunction.DoSql('GetDropAccount',{});
    await dispatch({ 
      type: 'ACCOUNTSDROP',
      payload: {
        Data: terdata,
        }
    })
  };


  const getDropConsultants = async () => {
    let terdata = await SqlFunction.DoSql('GetDropConsultant',{});
    await dispatch({ 
      type: 'CONSULTANTSDROP',
      payload: {
        Data: terdata,
        }
    })
  };

  const getDropContacts = async () => {
    let terdata = await SqlFunction.DoSql('GetDropContact',{});
    await dispatch({ 
      type: 'CONTACTSDROP',
      payload: {
        Data: terdata,
        }
    })
  };

  const getDropActtype = async () => {
    let terdata = await SqlFunction.DoSql('GetDropActTypeCa',{});
    await dispatch({ 
      type: 'ACTTYPEDROP',
      payload: {
        Data: terdata,
        }
    })
  };

  const getDropActstatus = async () => {
    let terdata = await SqlFunction.DoSql('GetDropActStatusCa',{});
    await dispatch({ 
      type: 'ACTSTATUSDROP',
      payload: {
        Data: terdata,
        }
    })
  };


  const getDropUsers = async () => {
    let terdata = await SqlFunction.DoSql('GetDropUserCa',{});
    await dispatch({ 
      type: 'USERSDROP',
      payload: {
        Data: terdata,
        }
    })
  };




  useEffect(() => {
    (async () => {
      try {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false
            }
          });

      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false
          }
        });
      }
    })();
  }, []);


  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return <DropDataContext.Provider value={{ ...state,
    method: "DROPDATA",
    getDropAccounts,
    getDropUsers,
    getDropConsultants,
    getDropContacts,
    getDropActtype,
    getDropActstatus,

  }}>
      {children}
    </DropDataContext.Provider>;
};
export default DropDataContext;