import LoadingScreen from "components/LoadingScreen";
import { createContext, useEffect, useReducer } from "react";
import { useAppState } from 'contexts/appState'

//import axios from "utils/axios"; // All types
// =============================================

const SqlFunction = require("../functions/sql_functions_app");
const axios = require('axios');

var Types;

(function (Types) {
  Types["Init"] = "INIT";
  Types["Login"] = "LOGIN";
  Types["Logout"] = "LOGOUT";
  Types["Register"] = "REGISTER";
})(Types || (Types = {}));

// ================================================


const MainDataContext = createContext({ 
  method: "MAINDATA",
  zetLaden: arg => {},
  getAllMainData: (type) => Promise.resolve()
}); // props type

export const MainDataProvider = ({
  children
}) => {
  const [ state, dispatch ] = useAppState()
  const getAllMainData = async (type) => {
    let sql = 'GetAllAcc';
    let gridname = 'AllAcc';
    let title = 'Bedrijven';
    let params = {}
    switch (type) 
    {

      case 'ALLACC':
        sql = 'GetAllAcc';
        gridname = 'AllAcc';
        title = 'Bedrijven';
        break;

      case 'ALLCONS':
        sql = 'GetAllConsCa';
        gridname = 'AllconsCa';
        title = 'Consultants';
        break;

      case 'ACTCONS':
        sql = 'GetAllConsCa';
        gridname = 'AllconsCa';
        title = 'Consultants';
        params = {type:'ACTCONS'};
        break;

      case 'BESCONS':
        sql = 'GetAllConsCa';
        gridname = 'AllconsCa';
        title = 'Consultants';
        params = {type:'BESCONS'};
        break;

      case 'ALLCONT':
        sql = 'GetAllCont';
        gridname = 'AllCont';
        title = 'Contactpersonen';
        break;

      case 'ALLREQ':
        sql = 'GetAllRequestCa';
        gridname = 'AllReqCa';
        title = 'Aanvragen';
        break;


      default:
        break;
    }        


    let terdata = await SqlFunction.DoSql(sql,params);
    await dispatch({ 
      type: 'ALLMAINDATA',
      payload: {
        Data: terdata && terdata.data ? terdata.data : [],
        Col: terdata && terdata.kol ? terdata.kol : [],
        GridName:gridname,
        Title:title,
        }
    })
  };

  const zetLaden = async () => {
    await dispatch({ 
      type: 'ZETLADEN',
    })
  };


  useEffect(() => {
    (async () => {
      try {
          dispatch({
            type: Types.Init,
            payload: {
              isInitialized: false
            }
          });

      } catch (err) {
        dispatch({
          type: Types.Init,
          payload: {
            isInitialized: false
          }
        });
      }
    })();
  }, []);


//  if (state.laden) {
//    return <LoadingScreen />;
//  }

  return <MainDataContext.Provider value={{ ...state,
    method: "MAINDATA",
    getAllMainData,
    zetLaden
  }}>
      {children}
    </MainDataContext.Provider>;
};
export default MainDataContext;